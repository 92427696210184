<template>
  <div>
    <img src="../assets/fail.png" alt="">
    <h2>{{ msg }}</h2>
    <p style="font-weight: bold">如有疑问，请返回公众号首页联系客服</p>
    <button @click="colse">返回首页</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: ''
    }
  },
  created() {
    document.title = '绑定失败'
  },
  mounted() {
    console.log('msg', this.$route.query.msg[0].message)

    if (this.$route.query.msg[0].message) {
      let code = this.$route.query.msg[0].message;
      // console.log(code.substring(0, 14))
      if (this.$route.query.msg[0].message.substring(0, 12) == "invalid code") {
        //code过期超过五分钟
        this.msg = '绑定失败，请退出重新加载网页'
      } else if (this.$route.query.msg[0].message.substring(0, 14) == "code been used") {
        //code已被使用
        this.msg = '绑定失败，请退出重新加载网页'
      } else {
        //如果都不是则返回后台报错
        this.msg = this.$route.query.msg[0].message
      }
    }
  },
  methods: {
    colse() {
      setTimeout(function () {
        document.addEventListener(
          'WeixinJSBridgeReady',
          function () {
            WeixinJSBridge.call('closeWindow')
          },
          false)
      })
      WeixinJSBridge.call('closeWindow')
      window.close()
    }
  }
}
</script>

<style>
img {
  margin-top: 100px;
  width: 100px;
  height: 100px;
}

button {
  width: 60%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 25px;
  line-height: 25px;
  margin-top: 20px;
}
</style>
