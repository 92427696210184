import Service from '@/api/index.js'
/**
 * 验证码校验
 * @param {*} data
 */
export function verifyCode(code, data) {
  return Service({
    url: `/api/v1/weChat/account/bind/short_message_verify?code=${code}`,
    method: 'post',
    data: JSON.stringify(data)
  })
}
/**
 * 发送验证码
 * @param {*} data
 */
export function sendCode(data) {
  return Service({
    url: '/api/v1/verify_code/short_message_send',
    method: 'post',
    data: JSON.stringify(data)
  })
}
/**
 * 发送覆盖绑定验证码
 * @param {*} data
 */
export function sendCoverCode(data) {
  return Service({
    url: '/api/v1/wechat/cover/short_message_verify_code',
    method: 'post',
    data: JSON.stringify(data)
  })
}
/**
 * 发送覆盖绑定验证码
 * @param {*} data
 */
export function register(code, data) {
  return Service({
    url: `/api/v1/wechat/register?code=${code}`,
    method: 'post',
    data: JSON.stringify(data)
  })
}
/**
 * 获取openID
 * @param {*} data
 */
export function getOpenID (data) {
  return Service({
    url: `api/v1/wechat/common/getOpenID?code=${data}`,
    method: 'get'
  })
}
/**
 * 获取token
 * @param {*} data
 */
// export function gettoken (data) {
//   return Service({
//     url: 'api/v5/wechat/auth',
//     method: 'post',
//     data: JSON.stringify(data)
//   })
// }

