<template>
  <div class="containerz">
    <div v-html="description"></div>
  </div>
</template>


<script>
const Swal = require("sweetalert2");
export default {
  props: ['description'], // 声明接收名为 description 的 prop
  mounted() {
    document.title = "产品详情"
    console.log(this.description)
  },
  // 监听
  watch: {},
  methods: {

  },
};
</script>

<style>
.containerz {
  /* width: 80%; */
  /* margin: 0 auto; */
  /* padding-top: 30px; */
  padding: 0 10px;
  height: 100vh;
  /* text-align: center; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  */
}
.containerz img {
  /* width: auto; */
  /* margin: 0 auto; */
  width: 100% !important;
  height: auto !important;
  margin-top: 0;
}
</style>
