<template>
    <div class="container">
        <img src="../assets/UserInfoCollection.png" alt="">
        <div class="back"></div>
        <div class="dialog">
            <div class="banner">
                <div>意大利Pentax宾泰克</div>
                <div>哪里有水 哪里就有宾泰克</div>
            </div>
            <form class="tel-form">
                <input id="name" name="name" v-model="name" placeholder="请输入姓名" class="form-input" />
                <input id="tel" name="tel" v-model="tel" placeholder="请输入电话" class="form-input" />
            </form>
            <button type="button" @click="productDetailBtn(1)" class="form-button">提交</button>
        </div>

        <div class="bottom">
            <span @click="productDetailBtn(0)">跳过 >></span>
        </div>
    </div>
</template>
  
  
<script>
import { qrCodeProductDetail } from "@/api/product.js";
import { saveQrcodeInfo, hasQrCodeScanRecords } from "@/api/qrcode.js";
import { getOpenID } from "@/api/request.js";
const Swal = require("sweetalert2");
export default {
    data() {
        return {
            code: null,
            dataId: null, //扫码记录id
            description: "",
            tel: "",
            name: "",
            wxCode: null,
            openId: null // 新增 openId 属性
        };
    },
    mounted() {
        document.title = "产品详情";
        //使用微信state作为参数
        const qrcode = this.$route.query.state;
        console.log("qrcode", qrcode)
        this.wxCode = this.$route.query.code;
        console.log("wxCode", this.wxCode);
        this.code = qrcode;
        this.getProductDetail();
    },
    // 监听
    watch: {},
    methods: {
        async getWxOpenID() {
            try {
                console.log("this.wxCode", this.wxCode)
                const res = await getOpenID(this.wxCode);
                let openId = res.openid
                console.log("openid", openId)
                this.openId = openId; // 保存 openId 到组件数据中
                return Promise.resolve(openId)
            } catch (e) {
                console.error("getWxOpenID", e);
                Swal.fire({
                    title: "获取微信信息失败" + e,
                    icon: "error",
                });
                return Promise.reject(e);
            }
        },
        async getProductDetail() {
            try {
                const openId = await this.getWxOpenID();
                console.log(openId)
                const res = await qrCodeProductDetail(this.code, openId);
                this.description = res.description;
                this.dataId = res.dataId;
                const hasQrScanRecord = res.scanRecord
                if (hasQrScanRecord) {
                    this.routerProduct()
                }
                //  查询是否有扫码记录
                // await this.hasQrScanRecords(openId)
            } catch (e) {
                console.error(e);
                Swal.fire({
                    title: "查询产品失败" + e,
                    icon: "error",
                });
            }
        },

        async submitTel() {
            try {
                if (this.tel === '') {
                    Swal.fire({
                        title: "请填写手机号",
                        icon: "error",
                    });
                    return false;
                } else if (!/^1[3456789]\d{9}$/.test(this.tel)) {
                    Swal.fire({
                        title: "手机号格式不正确",
                        icon: "error",
                    });
                    return false;
                }
                const params = {
                    code: this.code,
                    tel: this.tel,
                    name: this.name,
                    dataId: this.dataId,
                };
                await saveQrcodeInfo(params);
                Swal.fire({
                    title: "保存成功",
                    icon: "success",
                });
                return true;
            } catch (e) {
                Swal.fire({
                    title: "保存失败",
                    icon: "error",
                });
                return false;
            }
        },

        async productDetailBtn(data) {
            if (data === 1) {
                let result = await this.submitTel()
                console.log(result)
                if (!result) {
                    return;
                }
            }
            this.routerProduct()
        },


        async hasQrScanRecords(openId) {
            try {
                //如果有扫码记录直接跳转产品页
                let result = await hasQrCodeScanRecords(openId)
                console.dir(result)
                if (result.result.total > 0) {
                    this.routerProduct()
                }
            } catch (error) {
                console.error(e);
                Swal.fire({
                    title: "查询二维码失败",
                    icon: "error",
                });
            }
        },

        routerProduct() {
            this.$router.push({
                name: 'productDetail',
                params: {
                    description: this.description
                }
            });
        },

    },
};


</script>
  
<style >
.container {
    /* width: 80%; */
    padding-top: 0;
    position: relative;
    height: 100vh;
    /* background-image:url("../assets/UserInfoCollection.png") */
}

.container img {
    /* width: auto; */
    /* margin: 0 auto; */
    width: 100% !important;
    height: auto !important;
    margin-top: 0;
}

.dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 23px;
    line-height: 20px;
    background-color: rgba(255, 255, 255, 100);
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 42, 124, 0.2), 10px 0px 20px -10px rgba(0, 42, 124, 0.1), -10px 2px 20px -10px rgba(0, 42, 124, 0.1);
    border: 1px solid rgba(255, 255, 255, 100);
    height: 500px;
    width: 90%;
}

.dialog .banner {
    margin-top: 50px;
}

.dialog .banner div:first-child {
    font-family: PingFangSC-regular sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #002A7C;
    line-height: 28px;

}

.dialog .banner div:last-child {
    font-size: 14px;
    color: #9a9a9a;
    line-height: 20px;
    margin-top: 10px;
}

.dialog .tel-form {
    width: 100%;
}


.form-input {
    width: 80%;
    border: none;
    outline: none;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 1px solid #ccc;
}

.form-button {
    border-radius: 20px;
    font-size: 14px;
    height: 40px;
    /* width: 320px; */
    width: 80%;
    color: #fff;
    border: none;
    background-color: #002A7C;
    margin-bottom: 75px;
}

.form-button:hover {
    background-color: #002A7C;
}

/* 按钮样式 */

.bottom {
    position: absolute;
    bottom: 50%;
    transform: translateY(280px);
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 14px;
    color: rgba(154, 154, 154, 100);
}
</style>
  