import axios from 'axios'

const ConfigBaseURL = 'https://apiiot.bintaike.cn/';
// const ConfigBaseURL = 'http://iotapi-dev.bintaike.cn/'
// const ConfigBaseURL = 'http://127.0.0.1:8889/'
// 123
const Service = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
  // config.headers['X-AUTH-TOKEN'] = localStorage.getItem('token') || null
  config.headers['X-LANG'] = 'zh-CN'
  return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  // console.log(response)
  return response.data
}, error => {
  console.log('TCL: error', error.response.data)
  // const msg = error.Message !== undefined ? error.Message : ''
  // Message({
  //     message: '网络错误' + msg,
  //     type: 'error',
  //     duration: 3 * 1000
  // })
  return Promise.reject(error.response.data)
})
export default Service
