import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/Register.vue'
import RegComplete from '../views/RegComplete.vue'
import RegFail from '../views/RegFail.vue'
import BindAccount from '../views/BindAccount.vue'
import Complete from '../views/Complete.vue'
import Fail from '../views/Fail.vue'
import ProductDetail from '../views/ProductDetail.vue'
import UserInfoCollection from '../views/UserInfoCollection.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/bindAccount',
    name: 'bindAccount',
    component: BindAccount
  },
  {
    path: '/complete',
    name: 'complete',
    component: Complete
  },
  {
    path: '/fail',
    name: 'fail',
    component: Fail
  },
  {
    path: '/',
    name: 'register',
    component: Register
  },
  {
    path: '/regcomplete',
    name: 'regcomplete',
    component: RegComplete
  },
  {
    path: '/regfail',
    name: 'regfail',
    component: RegFail
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: ProductDetail,
    props: true
  },
  {
    path: '/userInfoCollection',
    name: 'userInfoCollection',
    component: UserInfoCollection,

  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
