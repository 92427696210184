import Service from '@/api/index.js'

/**
 * 保存二维码用户信息
 */
export function saveQrcodeInfo(data) {
    return Service({
      url: '/api/v1/qrcodes/user-info',
      method: 'post',
      data: JSON.stringify(data)
    })
  }


  /**
 * 通过openid查询是否有扫码记录
 * @param {*} data
 */
export function hasQrCodeScanRecords(data) {
  return Service({
    url: `/api/v1/qrcodes?trace=${data}`,
    method: 'get'
  })
}