<template>
  <div>
    <img src="../assets/success.png" alt="">
    <h2>绑定成功</h2>
    <p style="font-weight: bold">如有疑问，请返回公众号首页联系客服</p>
    <button @click="colse">返回首页</button>
  </div>
</template>

<script>
export default {
  created() {
    document.title = '绑定成功'
  },
  methods: {
    colse() {
      setTimeout(function () {
        document.addEventListener(
          'WeixinJSBridgeReady',
          function () {
            WeixinJSBridge.call('closeWindow')
          },
          false)
      })
      WeixinJSBridge.call('closeWindow')
      window.close()
    }
  }
}
</script>
<style>
img {
  margin-top: 100px;
  width: 100px;
  height: 100px;
}

button {
  width: 60%;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 25px;
  line-height: 25px;
  margin-top: 20px;
}
</style>
