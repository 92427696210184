import Service from '@/api/index.js'

/**
 * 扫码查询产品详情
 */
export function qrCodeProductDetail(code,traceCode) {
    return Service({
      url: `/api/v1/qrcode?code=${code}&traceCode=${traceCode}`,
      method: 'get',
    })
}