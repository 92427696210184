<template>
  <div class="min_content tel_content">
    <h3>注册账号</h3>
    <img src="../assets/logo_web.png" alt="">
    <form onsubmit="return false;">
      <input type="text" placeholder="输入手机号码" v-model.number="form.tel" />
      <transition name="slide-fade">
        <span class="warning" v-if="telShow">{{ phoneInfo }}</span>
      </transition>
      <div class="verification">
        <input type="text" placeholder="输入手机验证码" v-model="form.code" />
        <button :disabled="disableBtn" @click="sendCode">{{ authText }} {{ waitNum }}</button>
        <transition name="slide-fade">
          <span class="warning" v-if="codeShow">验证码不能为空</span>
        </transition>
      </div>
      <input class="all_line no_radius" type="submit" value="注册账号" @click="register" />
    </form>
  </div>
</template>

<script>
import { sendCode, sendCoverCode, verifyCode, register } from '@/api/request.js'
const Swal = require('sweetalert2');
export default {
  data() {
    return {
      openid: '',
      form: {
        tel: '',
        code: ''
      },
      telShow: false,
      codeShow: false,
      authText: '获取验证码',
      waitNum: '',
      timeLoop: '',
      disableBtn: false,
      phoneInfo: '手机号不能为空',
      code: ''
    }
  },
  mounted() {
    document.title = '注册账号'
    this.check()
  },
  // 监听
  watch: {
    'form.tel': function (val) {
      if (val !== '') {
        this.telShow = false
      }
    },
    'form.code': function (val) {
      if (val !== '') {
        this.codeShow = false
      }
    },
    telShow: function (val) {
      if (val) {
        this.disableBtn = true
      } else {
        this.disableBtn = false
      }
    }
  },
  methods: {
    async check() {
      // const str = 'https://iot.bintaike.cn/register?code=061mvBFa17ZChD0zwfGa1YuJV02mvBFw&state='
      // window.location.href
      // var domine = str.split('?')[1].split('&')
      var domine = window.location.href.split('?')[1].split('&')
      const obj = {}
      domine.forEach((item, i) => {
        item = item.split('=')
        obj[item[0]] = item[1]
      })
      this.guid = obj.guid
      this.code = obj.code
      console.log(obj.code)
    },
    async register() {
      // console.log(this.from.code)
      if (this.form.tel === '' && this.form.code === '') {
        this.codeShow = true
        this.telShow = true
        return false
      } else if (!(/^1[3456789]\d{9}$/.test(this.form.tel))) {
        this.phoneInfo = '手机格式不正确'
        this.telShow = true
        return false
      } else if (this.form.code === '') {
        this.telShow = false
        this.codeShow = true
        return false
      } else if (this.form.tel === '') {
        this.codeShow = false
        this.telShow = true
        return false
      } else {
        this.codeShow = false
        this.telShow = false
      }
      try {
        const params = {
          account: this.form.tel,
          verify_code: Number(this.form.code)
        }
        await register(this.code, params)
        // 提示成功
        this.$router.push({ path: '/regcomplete' })
        console.log("成功跳转")
      } catch (e) {
        console.log(e.errors.datas)
        this.$router.push({ path: '/regfail', query: { msg: e.errors.datas } })
      }
    },
    //判断号码不为空发送注册验证码
    async sendCode() {
      if (this.form.tel === '') {
        this.telShow = true
        return false
      } else if (!(/^1[3456789]\d{9}$/.test(this.form.tel))) {
        this.phoneInfo = '手机格式不正确'
        this.telShow = true
        return false
      }
      this.disableBtn = true
      try {
        const params = {
          phone: this.form.tel,
          verify_code_type: 1
        }
        let result = await sendCode(params)
        this.timer(90)
        console.dir(result);
        if (result.account_has_been_bound) {
          this.sendCoverCode();
        }
      } catch (e) {
        console.log(e)
        this.phoneInfo = e.errors.datas[0].message
        this.telShow = true
        this.disableBtn = false
      }
    },
    //注册绑定之后重复提示
    async sendCoverCode() {
      try {
        Swal.fire({
          // title: '提示',
          html: `该手机号已绑定到其他微信，是否重新与本微信号绑定？`,
          type: 'warning',
          icon: 'warning',
          allowOutsideClick: false,//如果设置为false，则用户无法通过单击外部来解除模态。
          allowEnterKey: false,//如果设置为false，则用户无法通过按Enter键或空格键确认模态，除非他们手动对焦确认按钮。
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: '继续绑定',
          // cancelButtonColor: '#28B78D',
          cancelButtonText: '取消',
          reverseButtons: true
        }).then(async (result) => {
          if (result.value) {
            const params = {
              phone: this.form.tel,
              verify_code_type: 3
            }
            await sendCoverCode(params)
            if (this.waitNum > 0) {
              this.waitNum = 90;
            } else {
              this.timer(90);
            }
          }
        });

      } catch (e) {
        console.log(e.errors.datas[0].message)
        this.phoneInfo = e.errors.datas[0].message
      }
    },
    //90s倒计时
    async timer(time) {
      this.waitNum = time
      this.authText = '重新发送'
      // 验证码倒计时60s
      this.timeLoop = setInterval(() => {
        if (this.waitNum > 0) {
          this.waitNum--
        } else {
          clearInterval(this.timeLoop)
          this.authText = '重新发送'
          this.waitNum = ''
          this.disableBtn = false
        }
      }, 1000)
    }
  }
}
</script>

<style>
.min_content {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
}

.tel_content img {
  width: 200px;
  margin: 60px auto;
}

.tel_content form {
  position: relative;
}

.tel_content form input {
  height: 44px;
  line-height: 44px;
  margin-top: 10px;
  outline: none;
}

.tel_content form .verification {
  position: relative;
}

.tel_content form .verification button {
  position: absolute;
  background: transparent;
  width: 100px;
  height: 44px;
  top: 0;
  right: 0;
  font-size: 14px;
  line-height: 14px;
  color: #1190ee;
  margin-top: 10px;
  border: 1px solid transparent;
}

button:focus {
  outline: 0;
}

.name_content input.no_radius,
.tel_content button,
.tel_content input.no_radius {
  margin-top: 30px;
}

input.all_line[type="submit"] {
  margin-top: 40px;
  background: linear-gradient(to bottom, #37c1fc, #0476e0);
  width: 100%;
  border: none;
  height: 44px;
  line-height: 44px;
  color: #fff;
  font-size: 16px;
  border-radius: 30px;
}

form input[type=text] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #1190ee;
  padding-left: 10px;
}

.warning {
  color: red;
  font-size: 12px;
  float: left;
  padding-left: 10px;
  position: absolute;
  left: 0;
  z-index: 99;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.swal2-styled {
  width: 120px;
}
</style>
